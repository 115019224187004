/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * Credentials flow type, referred to as the OIDC grant type
     * @type {string}
     * @memberof TokenRequest
     */
    grantType?: TokenRequestGrantTypeEnum;
    /**
     * clientId for a tractable customer (not a Cognito app client). Required for client_credentials flow, not used for authorization_code flow.
     * @type {string}
     * @memberof TokenRequest
     */
    clientId?: string;
    /**
     * ID for a Tractable product, such as `auto-platform-product-portal`. Required for client_credentials flow, not used for authorization_code flow.
     * @type {string}
     * @memberof TokenRequest
     */
    productId?: string;
    /**
     * Secret for the client credentials flow, the client secret string from the cognito app client. Required for client_credentials flow, not used for authorization_code flow.
     * @type {string}
     * @memberof TokenRequest
     */
    appClientSecret?: string;
    /**
     * Space-separated list of scopes such as `tram-api/write tram-api/read`. Required for client_credentials flow, not used for authorization_code flow.
     * @type {string}
     * @memberof TokenRequest
     */
    scope?: string;
    /**
     * OAuth authorization code. Required for authorization_code flow, not used for client_credentials flow.
     * @type {string}
     * @memberof TokenRequest
     */
    code?: string;
    /**
     * PKCE code verifier. Required for authorization_code flow, not used for client_credentials flow.
     * @type {string}
     * @memberof TokenRequest
     */
    codeVerifier?: string;
    /**
     * Tractable environment to log in to. Used in product pooled flows where the client is split across environments.
     * @type {string}
     * @memberof TokenRequest
     */
    env?: TokenRequestEnvEnum;
}

/**
* @export
* @enum {string}
*/
export type TokenRequestGrantTypeEnum =
    'authorization_code' | 
    'client_credentials'
/**
* @export
* @enum {string}
*/
export type TokenRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



/**
 * Check if a given object implements the TokenRequest interface.
 */
export function instanceOfTokenRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TokenRequestFromJSON(json: any): TokenRequest {
    return TokenRequestFromJSONTyped(json, false);
}

export function TokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grantType': !exists(json, 'grantType') ? undefined : json['grantType'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'appClientSecret': !exists(json, 'appClientSecret') ? undefined : json['appClientSecret'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'codeVerifier': !exists(json, 'codeVerifier') ? undefined : json['codeVerifier'],
        'env': !exists(json, 'env') ? undefined : json['env'],
    };
}

export function TokenRequestToJSON(value?: TokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grantType': value.grantType,
        'clientId': value.clientId,
        'productId': value.productId,
        'appClientSecret': value.appClientSecret,
        'scope': value.scope,
        'code': value.code,
        'codeVerifier': value.codeVerifier,
        'env': value.env,
    };
}

